import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Login from "./components/Login";
import Register from "./components/Register";
import Search from "./components/Search";
import { fetchToken, RequireToken } from "./utils/Auth";
import { Button, Flex, Layout } from "antd";

const { Header, Content, Footer } = Layout;

function App() {
  const handleLogout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("username");
    localStorage.removeItem("user_id");
    window.location.href = "/";
  }

  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Header>
        <Flex justify="space-between" align="center">
          <a href="/" style={{ color: "white" }}>
            <h1 style={{margin: 0}}>Kmuch</h1>
          </a>
          {fetchToken() && <Button type="primary" onClick={handleLogout}>Logout</Button>}
        </Flex>
      </Header>
      <Content>
        <BrowserRouter>
          <Routes>
            <Route path="/">
              <Route index element={<Navigate to="/login" />} />
              <Route
                path="login"
                element={
                  fetchToken() ? (
                    <Navigate to="/search" />
                  ) : (
                    <div style={{ paddingTop: "10%" }}>
                      <Login />
                    </div>
                  )
                }
              />
              <Route
                path="signup"
                element={
                  fetchToken() ? (
                    <Navigate to="/search" />
                  ) : (
                    <div style={{ paddingTop: "10%" }}>
                      <Register />
                    </div>
                  )
                }
              />
              <Route
                path="search"
                element={
                  <RequireToken>
                    <Search />
                  </RequireToken>
                }
              />
            </Route>
          </Routes>
        </BrowserRouter>
      </Content>
      <Footer
        style={{
          textAlign: "center",
        }}
      >
        <a href="/">Kmuch</a> ©{new Date().getFullYear()} Created by Bit
      </Footer>
    </Layout>
  );
}

export default App;
